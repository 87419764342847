import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import { Link } from "react-router-dom";

function Portfolio() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Portfolio</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Portfolio
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Project Section Start */}
        <section className="project-section section-padding fix">
            <div className="container">
                <div className="row g-4">
                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project01.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                        <Link to="https://ssmsolutions.in/" target="_blank">SSM Solutions</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project02.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                        <Link to="https://www.aztecindia.org/" target="_blank">Aztec Fluids And Machinery</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project03.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                        <Link to="https://www.nayanfilmsphotography.com/" target="_blank">Nayan Photography</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project04.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                    <Link to="https://www.stinkout.in/" target="_blank">Stinkout</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project05.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                        <Link to="https://www.shreedesignstudio.com/" target="_blank">Shree Design Studio</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project06.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                    <Link to="/portfolio">Shivam Homeopathy</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project07.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                    <Link to="https://www.aarshphotography.ca/" target="_blank">Aarsh Photography</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project08.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                    <Link to="http://www.lowcountryfinancialservices.com/" target="_blank">Lowcountry Financial Services</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project09.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design</p>
                                    <h4>
                                    <Link to="/portfolio">UpperCrust Wealth</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project10.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                    <Link to="https://www.precisionsf.com/" target="_blank">Precision Tile</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project11.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Business Card Design</p>
                                    <h4>
                                    <Link to="/portfolio">ITAG Equipment</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project12.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Brochure Design</p>
                                    <h4>
                                    <Link to="/portfolio">Hreem Om Worldwide</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project13.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Business Card Design</p>
                                    <h4>
                                    <Link to="/portfolio">Shivam Homeopathy</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project14.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Book Design</p>
                                    <h4>
                                    <Link to="/portfolio">Because Emotions Are Binary</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project15.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Web Design & Development</p>
                                    <h4>
                                    <Link to="https://www.spaceoartdesign.com/" target="_blank">Spaceo Art Design</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project16.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">King Karts</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project17.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">Yogi Ayu Clinic</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project18.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">Hair, Body & Beyond</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project19.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">CnetGeek</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project20.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">Amee Multispeciality Hospital</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project21.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">One Rank Blog</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project22.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">Tryouts 2020</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project23.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">The Gregory Nesmith Show</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                        <div className="project-items">
                            <div className="project-image">
                                <img src="assets/img/project/project24.jpg" alt="Web Design Services" />
                                <div className="project-content">
                                    <p>Logo Design</p>
                                    <h4>
                                    <Link to="/portfolio">Basketball Challenge</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </section>
            

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Portfolio;
import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menu from "./Menu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";
// import Preloader from "./Preloader";




function Index() {
    
    
    return (

        
        <Fragment>
            
            {/* Preloader Start */}
            {/* <Preloader/> */}
            
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menu/>

            {/* Hero Section Start */}
            <section className="hero-section fix hero-3">
                <div className="bottom-shape">
                    <img src="assets/img/hero/bottom-shape.png" alt="Web Design Services" />
                </div>
                <div className="array-button">
                    <button className="array-prev"><i className="fal fa-arrow-right"></i></button>
                    <button className="array-next"><i className="fal fa-arrow-left"></i></button>
                </div>
                <div className="swiper hero-slider">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="slider-image bg-cover" style={{ backgroundImage: `url("assets/img/hero/hero-2.jpg")` }}>

                                <div className="border-shape" data-animation="slideInRight" data-duration="3s" data-delay="2.2s">
                                    <img src="assets/img/hero/border-shape.png" alt="Web Design Services" />
                                </div>

                            </div>
                            <div className="container">
                                <div className="row g-4 align-items-center">
                                    <div className="col-lg-8">
                                        <div className="hero-content">
                                            <h5 data-animation="slideInRight" data-duration="2s" data-delay=".3s">Ideal Infotech</h5>
                                            <h1 data-animation="slideInRight" data-duration="2s" data-delay=".5s">
                                                Designing Your<br />Digital Success
                                            </h1>
                                            <p data-animation="slideInRight" data-duration="2s" data-delay=".9s">
                                                From captivating designs to seamless functionality, we're dedicated to<br />
                                                architecting your online presence for maximum impact and success.
                                            </p>
                                            <div className="hero-button">
                                                <Link to="/about" data-animation="slideInRight" data-duration="2s"
                                                    data-delay=".9s" className="theme-btn hover-white">Explore More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                                
                                                <Link to="/contact" data-animation="slideInRight" data-duration="2s"
                                                    data-delay=".9s" className="theme-btn border-white">Contact Us<i className="fa-solid fa-arrow-right-long"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="slider-image bg-cover" style={{ backgroundImage: `url("assets/img/hero/hero-1.jpg")` }}>

                                <div className="border-shape" data-animation="slideInRight" data-duration="3s" data-delay="2.2s">
                                    <img src="assets/img/hero/border-shape.png" alt="Web Design Services" />
                                </div>

                            </div>
                            <div className="container">
                                <div className="row g-4 align-items-center">
                                    <div className="col-lg-8">
                                        <div className="hero-content">
                                            <h5 data-animation="slideInRight" data-duration="2s" data-delay=".3s">Ideal Infotech</h5>
                                            <h1 data-animation="slideInRight" data-duration="2s" data-delay=".5s">
                                                Elevating<br />Web Experiences
                                            </h1>
                                            <p data-animation="slideInRight" data-duration="2s" data-delay=".9s">
                                                We transform your vision into a reality that resonates with your <br />audience and drives tangible results.
                                            </p>
                                            <div className="hero-button">
                                                <Link to="/about" data-animation="slideInRight" data-duration="2s"
                                                    data-delay=".9s" className="theme-btn hover-white">Explore More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                                
                                                <Link to="/contact" data-animation="slideInRight" data-duration="2s"
                                                    data-delay=".9s" className="theme-btn border-white">Contact Us<i className="fa-solid fa-arrow-right-long"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="slider-image bg-cover" style={{ backgroundImage: `url("assets/img/hero/hero-3.jpg")` }}>

                                <div className="border-shape" data-animation="slideInRight" data-duration="3s" data-delay="2.2s">
                                    <img src="assets/img/hero/border-shape.png" alt="Web Design Services" />
                                </div>

                            </div>
                            <div className="container">
                                <div className="row g-4 align-items-center">
                                    <div className="col-lg-8">
                                        <div className="hero-content">
                                            <h5 data-animation="slideInRight" data-duration="2s" data-delay=".3s">Ideal Infotech</h5>
                                            <h1 data-animation="slideInRight" data-duration="2s" data-delay=".5s">
                                                Inspiring<br />Digital Journeys
                                            </h1>
                                            <p data-animation="slideInRight" data-duration="2s" data-delay=".9s">
                                                Let us be your partner in shaping your digital success story.
                                            </p>
                                            <div className="hero-button">
                                                <Link to="/about" data-animation="slideInRight" data-duration="2s"
                                                    data-delay=".9s" className="theme-btn hover-white">Explore More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                                
                                                <Link to="/contact" data-animation="slideInRight" data-duration="2s"
                                                    data-delay=".9s" className="theme-btn border-white">Contact Us<i className="fa-solid fa-arrow-right-long"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Section Start */}
            <section className="about-section section-padding fix bg-cover">
                <div className="container">
                    <div className="about-wrapper-2">
                        <div className="row">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay=".4s">
                                <div className="about-image">
                                    <div className="shape-image">
                                        <img src="assets/img/about/shape.png" alt="Web Design Services" />
                                    </div>
                                    <div className="circle-shape">
                                        <img src="assets/img/about/circle.png" alt="Web Design Services" />
                                    </div>
                                    <img src="assets/img/about/05.png" alt="Web Design Services" />

                                </div>
                            </div>
                            <div className="col-lg-6 mt-4 mt-lg-0">
                                <div className="about-content">
                                    <div className="section-title">
                                        <span className="wow fadeInUp">Ideal Infotech</span>
                                        <h2 className="wow fadeInUp" data-wow-delay=".3s">
                                            Let us help you reach <br /> new heights of success
                                        </h2>
                                    </div>
                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        We believe in pushing boundaries and embracing innovation to stay ahead of the curve. From sleek designs to seamless functionality, we're committed to delivering solutions that exceed expectations and leave a lasting impression.<br /><br />Whether you're looking to launch a new website, revamp your online presence, or drive more traffic to your business, we're here to help. Get in touch with us today to discuss your project and discover how we can turn your vision into reality.
                                    </p>
                                    <div className="icon-area wow fadeInUp" data-wow-delay=".7s">
                                        <ul className="list">
                                            <li>
                                                <i className="fa-solid fa-check"></i>
                                                Professional Team Members
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check"></i>
                                                Cutting-edge IT Solutions Company
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check"></i>
                                                Dedicated Tech Services
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".8s">
                                            <Link to="/about" className="theme-btn">Explore More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                        <div className="author-icon wow fadeInUp" data-wow-delay=".9s">
                                            <div className="icon">
                                                <i className="fa-solid fa-phone"></i>
                                            </div>
                                            <div className="content">
                                                <span>Call Us Now</span>
                                                <h5>
                                                    <a href="tel:+919601888812">+91-96018 88812</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Brand Section Start */}
            <div className="brand-section fix section-padding pt-0">
                <div className="container">
                    <div className="brand-wrapper">
                        <h6 className="text-center wow fadeInUp" data-wow-delay=".3s">100+ Brands Trust Us</h6>
                        <div className="swiper brand-slider">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client1.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client2.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client3.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client4.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client5.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client6.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client7.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client8.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client9.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client10.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client11.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client12.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client13.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client14.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client15.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client16.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client17.png" alt="Logo Design" />
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-image">
                                        <img src="assets/img/brand/client18.png" alt="Logo Design" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Service Section Start */}
            <section className="service-section-3 pb-0 fix section-padding bg-cover"
                style={{ backgroundImage: `url("assets/img/service/service-bg-3.jpg")` }}>
                <div className="container">
                    <div className="section-title-area">
                        <div className="section-title">
                            <span>What We Do</span>
                            <h2>

                                Services With The <br /> Greatest Depth Of Experience
                            </h2>
                        </div>
                        <Link to="/services" className="theme-btn">Check all Services<i className="fa-solid fa-arrow-right-long"></i></Link>
                        
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="service-card-items">
                                <div className="service-image">
                                    <img src="assets/img/service/webdesign-home.jpg"alt="Web Design Services" />
                                </div>
                                <div className="icon-2">
                                    <img src="assets/img/service/icon/s-icon-10.svg" alt="Website Design" />
                                </div>
                                <div className="service-content">
                                    <div className="icon">
                                        <img src="assets/img/service/icon/s-icon-10.svg" alt="Website Design" />
                                    </div>
                                    <h4>
                                        <Link to="/webdesign">Website Design</Link>
                                    </h4>
                                    <p>
                                        Create visually appealing and user-friendly website
                                    </p>
                                    <Link to="/webdesign" className="theme-btn-2 mt-3">Learn More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="service-card-items">
                                <div className="service-image">
                                    <img src="assets/img/service/web-development-home.jpg" alt="Web Design Services" />
                                </div>
                                <div className="icon-2">
                                    <img src="assets/img/service/icon/s-icon-4.svg" alt="Website Design" />
                                </div>
                                <div className="service-content">
                                    <div className="icon">
                                        <img src="assets/img/service/icon/s-icon-4.svg" alt="Website Design" />
                                    </div>
                                    <h4>
                                        <Link to="/webdevelopment">Web Development</Link>
                                    </h4>
                                    <p>
                                        Building websites with custom functionality
                                    </p>
                                    <Link to="/webdevelopment" className="theme-btn-2 mt-3">Learn More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="service-card-items">
                                <div className="service-image">
                                    <img src="assets/img/service/corporate-identity-home.jpg" alt="Web Design Services" />
                                </div>
                                <div className="icon-2">
                                    <img src="assets/img/service/icon/s-icon-10.svg" alt="Website Design" />
                                </div>
                                <div className="service-content">
                                    <div className="icon">
                                        <img src="assets/img/service/icon/s-icon-10.svg" alt="Website Design" />
                                    </div>
                                    <h4>
                                    <Link to="/corporateid">Corporate Identity</Link>
                                    </h4>
                                    <p>
                                        Logos, and Brochures to enhance branding
                                    </p>
                                    <Link to="/corporateid" className="theme-btn-2 mt-3">Learn More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="service-card-items">
                                <div className="service-image">
                                    <img src="assets/img/service/web-hosting-home.jpg" alt="Web Design Services" />
                                </div>
                                <div className="icon-2">
                                    <img src="assets/img/service/icon/s-icon-13.svg" alt="Website Design" />
                                </div>
                                <div className="service-content">
                                    <div className="icon">
                                        <img src="assets/img/service/icon/s-icon-13.svg" alt="Website Design" />
                                    </div>
                                    <h4>
                                    <Link to="/webhosting">Web Hosting</Link>
                                    </h4>
                                    <p>
                                        Reliable and high-performance web hosting
                                    </p>
                                    <Link to="/webhosting" className="theme-btn-2 mt-3">Learn More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cta-banner-2 section-padding">
                    <div className="container">
                        <div className="cta-wrapper-2">
                            <div className="author-icon">
                                <div className="icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="content">
                                    <span>Call Us Now</span>
                                    <h4>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                    </h4>
                                </div>
                            </div>
                            <h3>
                                Ready to elevate <br /> your online presence?
                            </h3>
                            <Link to="/contact" className="theme-btn bg-white">Get A Quote<i className="fa-solid fa-arrow-right-long"></i></Link>
                            
                        </div>
                    </div>
                </div>
            </section>

            {/* Work Process Section Start */}
            <section className="work-process-section fix section-padding pt-0">
                <div className="container">
                    <div className="section-title text-center">
                        <span>How IT works</span>
                        <h2>Standard Work Process</h2>
                    </div>
                    <div className="process-work-wrapper">
                        <div className="line-shape">
                            <img src="assets/img/process/linepng.png" alt="web design" />
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="work-process-items text-center">
                                    <div className="icon">
                                        <img src="assets/img/process/02.svg" alt="Website Design" />
                                        <h6 className="number">
                                            1
                                        </h6>
                                    </div>
                                    <div className="content">
                                        <h4>Project Discovery and Planning</h4>
                                        <p>
                                            Define project objectives, goals, and requirements through discussions with the client.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="work-process-items text-center">
                                    <div className="content style-2">
                                        <h4>Design Phase</h4>
                                        <p>
                                            Develop wireframes and prototypes to visualize the website layout and user interface.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <img src="assets/img/process/01.svg" alt="Website Design" />
                                        <h6 className="number">
                                            2
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="work-process-items text-center">
                                    <div className="icon">
                                        <img src="assets/img/process/03.svg" alt="Website Design" />
                                        <h6 className="number">
                                            3
                                        </h6>
                                    </div>
                                    <div className="content">
                                        <h4>Development Phase</h4>
                                        <p>
                                            Write clean, maintainable code for front-end and back-end.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="work-process-items text-center">
                                    <div className="content style-2">
                                        <h4>Deployment and Launch</h4>
                                        <p>
                                            Prepare the website for deployment by configuring servers, setting up hosting, and securing domain names.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <img src="assets/img/process/04.svg" alt="Website Design" />
                                        <h6 className="number">
                                            4
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Achievement Section Start */}
            <section className="achievement-section-3 fix section-bg-2">
                <div className="shape-image wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                    <img src="assets/img/achiv-shape.png" alt="Web Design Services" />
                </div>
                <div className="container">
                    <div className="achievement-wrapper style-2">
                        <div className="section-title mb-0">
                            <span className="text-white wow fadeInUp">Ideal Infotech</span>
                            <h2 className="text-white wow fadeInUp" data-wow-delay=".3s">
                                Turning Goals <br /> into Achievements
                            </h2>
                        </div>
                        <div className="counter-area">
                            <div className="counter-items wow fadeInUp" data-wow-delay=".3s">
                                <div className="icon">
                                    <img src="assets/img/achievement-icon/01.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h2><span className="count">500</span>+</h2>
                                    <p>Satisfied Clients</p>
                                </div>
                            </div>
                            <div className="counter-items wow fadeInUp" data-wow-delay=".5s">
                                <div className="icon">
                                    <img src="assets/img/achievement-icon/02.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h2><span className="count">650</span>+</h2>
                                    <p>Finished Projects</p>
                                </div>
                            </div>
                            <div className="counter-items wow fadeInUp" data-wow-delay=".7s">
                                <div className="icon">
                                    <img src="assets/img/achievement-icon/03.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h2><span className="count">10</span>+</h2>
                                    <p>Skilled Experts</p>
                                </div>
                            </div>
                            <div className="counter-items wow fadeInUp" data-wow-delay=".9s">
                                <div className="icon">
                                    <img src="assets/img/achievement-icon/04.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h2><span className="count">800</span>+</h2>
                                    <p>Logo Created</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Project Section Start */}
            <section className="project-section-3 section-padding pb-0 fix bg-cover"
                style={{ backgroundImage: `url("assets/img/testimonial/bg.jpg")` }}>
                <div className="container">
                    <div className="section-title-area">
                        <div className="section-title">
                            <span className="text-white">PROJECTS</span>
                            <h2 className="text-white">
                                Our Recent <br /> Work Portfolio
                            </h2>
                        </div>

                    </div>
                    <div className="project-wrapper style-2">
                        <div className="swiper project-slider-3">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="project-items style-2">
                                        <div className="project-image">
                                            <img src="assets/img/project/project01.jpg" alt="Web design work" width={450} height={450} />
                                            <div className="project-content">
                                                <p>Web Design & Development</p>
                                                <h4>
                                                <Link to="/portfolio">SSM Solutions</Link>
                                                </h4>
                                                <Link to="/portfolio" className="arrow-icon-2"><i className="fa-solid fa-arrow-right"></i></Link>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="project-items style-2">
                                        <div className="project-image">
                                            <img src="assets/img/project/project02.jpg" alt="Web design work" />
                                            <div className="project-content">
                                                <p>Web Design & Development</p>
                                                <h4>
                                                <Link to="/portfolio">Aztec</Link>
                                                </h4>
                                                <Link to="/portfolio" className="arrow-icon-2"><i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="project-items style-2">
                                        <div className="project-image">
                                            <img src="assets/img/project/project03.jpg" alt="Web design work" />
                                            <div className="project-content">
                                                <p>Web Design & Development</p>
                                                <h4>
                                                <Link to="/portfolio">Nayan Photography</Link>
                                                </h4>
                                                <Link to="/portfolio" className="arrow-icon-2"><i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="project-items style-2">
                                        <div className="project-image">
                                            <img src="assets/img/project/project04.jpg" alt="Web design work" />
                                            <div className="project-content">
                                                <p>Web Design & Development</p>
                                                <h4>
                                                <Link to="/portfolio">Stinkout</Link>
                                                </h4>
                                                <Link to="/portfolio" className="arrow-icon-2"><i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="project-items style-2">
                                        <div className="project-image">
                                            <img src="assets/img/project/project05.jpg" alt="Web design work" />
                                            <div className="project-content">
                                                <p>Web Design & Development</p>
                                                <h4>
                                                <Link to="/portfolio">Shree Design Studio</Link>
                                                </h4>
                                                <Link to="/portfolio" className="arrow-icon-2"><i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="project-items style-2">
                                        <div className="project-image">
                                            <img src="assets/img/project/project06.jpg" alt="Web design work" />
                                            <div className="project-content">
                                                <p>Web Design & Development</p>
                                                <h4>
                                                <Link to="/portfolio">Shivam Homeopathy</Link>
                                                </h4>
                                                <Link to="/portfolio" className="arrow-icon-2"><i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-dot-2 mr-left">
                        <div className="dot-2"></div>
                    </div>
                </div>
            </section>



            {/* Marquee Section Start */}
            <Marquee/>


            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Index;
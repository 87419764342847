import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Corporateid() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Corporate Identity</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Corporate Identity
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li className="active"><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/corporate-identity.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Corporate Identity</h3>
                                    <p className="mt-3">
                                    <strong>Brand Strategy:</strong> We start by understanding your company's values, mission, target audience, and market positioning. We conduct research to gain insights into your industry landscape, competitors, and audience perceptions to inform our strategy.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Logo Design:</strong> We create a distinctive and memorable logo that reflects your brand's personality and values. Our logo design process involves brainstorming concepts, sketching ideas, and refining designs until we achieve a logo that resonates with your audience and communicates your brand identity effectively.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Visual Elements:</strong> We develop a cohesive visual identity system that includes elements such as color palette, typography, and imagery. These visual elements are chosen carefully to evoke the desired emotions and associations, creating a consistent and recognizable brand presence across all touchpoints.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Collateral Design:</strong> We design various marketing collateral and brand materials, such as business cards, letterheads, brochures, signage, packaging, and digital assets. These materials reinforce your brand identity and help create a unified brand experience for your audience.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Brand Messaging:</strong> We craft compelling brand messaging and tone of voice guidelines that articulate your brand's story, values, and key messages. Consistent and authentic messaging helps strengthen brand recognition and build trust with your audience.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Brand Guidelines:</strong> We document all aspects of your corporate identity, including logo usage, color specifications, typography guidelines, and brand voice, in a comprehensive brand style guide. This guide serves as a reference for internal and external stakeholders to ensure consistent brand representation across all communications.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Implementation:</strong> We assist in implementing your corporate identity across various channels and platforms, including your website, social media profiles, advertising campaigns, and physical spaces. We provide guidance and support to ensure that your brand is represented accurately and effectively in every context.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Feedback and Iteration:</strong> We value collaboration and feedback throughout the design process. We work closely with you to refine and iterate on the design until we achieve a corporate identity that aligns with your vision and resonates with your audience.
                                    </p>
                                    <p className="mt-3">
                                    By creating a strong and cohesive corporate identity, we help you differentiate your brand, build brand equity, and establish a lasting connection with your audience. If you're ready to elevate your brand's identity, please contact us. We're here to help you bring your brand to life and achieve your business goals.
                                    </p>
                                    
                                </div>
                                <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Corporateid;
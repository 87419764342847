import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Menuu(){
    return(
        <Fragment>
            <header>
                <div class="header-top-section top-style-3">
                    <div class="container">
                        <div class="header-top-wrapper">
                            <ul class="contact-list">
                                <li>
                                    <i class="far fa-envelope"></i>
                                    <a href="mailto:info@idealinfotech.com" class="link">info@idealinfotech.com</a>
                                </li>
                                <li>
                                    <i class="fa-solid fa-phone-volume"></i>
                                    <a href="tel:+919601888812">+91-96018 88812</a>
                                </li>
                            </ul>
                            <div class="top-right">

                                <div class="social-icon d-flex align-items-center">
                                    <span>Follow Us:</span>
                                    <Link to="https://www.facebook.com/webdsolutions" target="_blank"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="https://twitter.com/webdsolutions" target="_blank"><i className="fab fa-twitter"></i></Link>
                                    <Link to="https://www.instagram.com/idealinfotech_/" target="_blank"><i className="fab fa-instagram"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="header-sticky" class="header-3">

                    <div class="container">
                        <div class="mega-menu-wrapper">
                            <div class="header-main">
                                <div class="header-left">
                                    <div class="logo">
                                        <Link to="https://www.shivamhomeopathic.com/" className="header-logo"><img src="assets/img/logo/logo.png" alt="Web Design Services" /></Link>  
                                    </div>
                                </div>
                                <div class="header-right d-flex justify-content-end align-items-center">
                                    <div class="mean__menu-wrapper">
                                        <div class="main-menu">
                                            <nav id="mobile-menu">
                                                <ul>
                                                    <li>
                                                        <Link to="https://www.shivamhomeopathic.com/">Home</Link>
                                                        
                                                    </li>
                                                    <li>
                                                        <Link to="/about">About</Link>
                                                    </li>
                                                    <li>
                                                    <Link to="/services">Services <i className="fas fa-angle-down"></i></Link>
                                                        <ul class="submenu">
                                                            <li><Link to="/webdesign">Website Design</Link></li>
                                                            <li><Link to="/webdevelopment">Web Development</Link></li>
                                                            <li><Link to="/corporateid">Corporate Identity</Link></li>
                                                            <li><Link to="/webhosting">Web Hosting</Link></li>
                                                            <li><Link to="/domain">Domain Registration</Link></li>
                                                            <li><Link to="/logodesign">Logo Design</Link></li>
                                                            <li><Link to="/webredesign">Website Re-Design</Link></li>
                                                            <li><Link to="/content">Content Writing</Link></li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <Link to="/portfolio">Portfolio</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact">Contact</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    <div class="header-button">
                                        <Link to="/contact" className="theme-btn bg-white"><span>Get A Quote <i className="fa-solid fa-arrow-right-long"></i></span></Link>
                                    </div>
                                    <div class="header__hamburger d-lg-none my-auto">
                                        <div class="sidebar__toggle">
                                            <i class="fas fa-bars"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default Menuu;
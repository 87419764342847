import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Domain() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Domain Registration</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Domain Registration
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li className="active"><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/domain-registration.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Domain Registration</h3>
                                    <p className="mt-3">
                                    <strong>Domain Name Selection:</strong> We help you choose the perfect domain name for your website, considering factors such as brand identity, relevance, and availability. We brainstorm ideas, conduct domain name searches, and provide suggestions to ensure you find the right domain for your business.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Domain Availability Check:</strong> Once you've selected a domain name, we check its availability to ensure it hasn't already been registered by someone else. If the domain is available, we proceed with the registration process. If not, we explore alternative options or variations.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Registration Process:</strong> We handle the domain registration process on your behalf, taking care of all the necessary paperwork and technical requirements. We work with accredited domain registrars to ensure a smooth and seamless registration process.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Domain Management:</strong> After registering your domain, we provide ongoing management services to ensure it remains active and up-to-date. This includes renewing the domain registration before it expires, updating contact information, and managing domain name servers (DNS).
                                    </p>
                                    <p className="mt-3">
                                    <strong>Privacy Protection:</strong> We offer optional privacy protection services to keep your personal information private and secure. With privacy protection, your contact details are replaced with generic information in the domain registration database, reducing the risk of spam and identity theft.
                                    </p>
                                    <p className="mt-3">
                                    <strong>DNS Configuration:</strong> We configure the domain name servers (DNS) to point to the web hosting server where your website is hosted. This ensures that visitors can access your website by typing your domain name into their web browser.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Email Setup:</strong> If needed, we assist with setting up custom email addresses using your domain name (e.g., info@yourdomain.com). We configure email forwarding, spam filtering, and other email services to meet your business communication needs.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Renewal Reminders:</strong> We provide timely reminders when your domain registration is due for renewal, ensuring you never lose ownership of your domain inadvertently.
                                    </p>
                                    <p className="mt-3">
                                    By handling the domain registration process for you, we simplify the technical aspects of establishing your online presence, allowing you to focus on building and growing your business. If you're ready to register your domain or have any questions, please don't hesitate to contact us. We're here to help you every step of the way.
                                    </p>
                                   
                                    
                                </div>
                                <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Domain;
import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Faq() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">FAQs</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                FAQs
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Faq Section Start */}
        <section className="faq-section-2 fix section-padding">
            <div className="container">
                <div className="faq-wrapper">
                    <div className="row g-4">
                        
                        <div className="col-lg-12">
                            <div className="faq-content style-2">
                                <div className="section-title">
                                    <span className="wow fadeInUp">Have a question in your mind?</span>
                                    <h2 className="wow fadeInUp" data-wow-delay=".3s">
                                        Feel free to ask anything<br/>you'd like to know.
                                    </h2>
                                </div>
                                <div className="faq-accordion mt-4 mt-md-0">
                                <div className="accordion" id="accordion">
                                    
                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                                What services do you offer?
                                            </button>
                                        </h5>
                                        <div id="faq1" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Our agency specializes in a wide range of services including Web Design, Web Development, Graphic Design, e-Commerce Solutions, Content Writing, Responsive Web Design, Corporate Website, CMS, PSD to HTML5, Bootstrap 5 Website, Logo Design, Brand Identity Design, Print Design, Digital Design, Marketing Collateral, Publication Design, Environmental Design, UI/UX Design, Illustration, Web Hosting, Domain Registration and more.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="true" aria-controls="faq1">
                                                How much does it cost to design a website?
                                            </button>
                                        </h5>
                                        <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                The cost of designing a website can vary depending on factors such as the complexity of the project, features required, and specific client needs. We offer customized solutions tailored to meet your budget and requirements.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".7s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                                How long does it take to complete a website design project?
                                            </button>
                                        </h5>
                                        <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                The timeline for completing a website design project can vary depending on the scope and complexity of the project. We work closely with our clients to establish realistic timelines and ensure timely delivery of projects.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="true" aria-controls="faq1">
                                                Do you provide ongoing support and maintenance for websites?
                                            </button>
                                        </h5>
                                        <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Yes, we offer ongoing support and maintenance services to ensure that your website remains up-to-date, secure, and performs optimally. Our team is available to address any issues or updates that may arise post-launch.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="true" aria-controls="faq1">
                                                Do you offer responsive web design?
                                            </button>
                                        </h5>
                                        <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Yes, we specialize in responsive web design to ensure that your website looks and functions seamlessly across all devices, including desktops, tablets, and smartphones.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq6" aria-expanded="true" aria-controls="faq1">
                                                Can you help with website hosting and domain registration?
                                            </button>
                                        </h5>
                                        <div id="faq6" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Absolutely, we offer website hosting and domain registration services to simplify the process of launching your website. We can assist you in selecting the right hosting plan and registering a domain name that aligns with your brand.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq7" aria-expanded="true" aria-controls="faq1">
                                                Do you provide content writing services for websites?
                                            </button>
                                        </h5>
                                        <div id="faq7" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Yes, we offer professional content writing services to create engaging and SEO-friendly content for your website. Our team of experienced writers can help you communicate your message effectively and connect with your target audience.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq8" aria-expanded="true" aria-controls="faq1">
                                                Can you help with website redesign projects?
                                            </button>
                                        </h5>
                                        <div id="faq8" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Yes, we specialize in website redesign projects to refresh the look and functionality of existing websites. Whether you're looking to update your website's design, improve user experience, or implement new features, we can help you achieve your goals.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq9" aria-expanded="true" aria-controls="faq1">
                                                How do I get started with a website design project?
                                            </button>
                                        </h5>
                                        <div id="faq9" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Getting started is easy! Simply reach out to us to schedule a consultation. During the consultation, we'll discuss your project goals, requirements, and budget to develop a customized plan that meets your needs.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 wow fadeInUp" data-wow-delay=".3s">
                                        <h5 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq10" aria-expanded="true" aria-controls="faq1">
                                                Do you have examples of previous work/portfolio?
                                            </button>
                                        </h5>
                                        <div id="faq10" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                                Yes, we have a portfolio showcasing our previous work and projects. You can view examples of our design and development work to get an idea of the quality and style of our work.
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Faq;
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Marquee(){
    return(
        <Fragment>
            <div className="marque-section section-padding">
                <div className="container-fluid">
                    <div className="marquee-wrapper style-2 text-slider">
                        <div className="marquee-inner to-left">
                            <ul className="marqee-list d-flex">
                                <li className="marquee-item style-2">
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdesign">Website Design</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdevelopment">Web Development</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/corporateid">Corporate Identity</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webhosting">Web Hosting</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/domain">Domain Registration</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/logodesign">Logo Design</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webredesign">Website Re-Design</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/content">Content Writing</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdesign">Responsive Web Design</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdesign">E-Commerce Solutions</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdesign">Corporate Website</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdesign">CMS</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdesign">PSD to HTML5</Link></span>
                                    <span className="text-slider"><img src="assets/img/asterisk.svg" alt="Website Design" /></span><span
                                        className="text-slider text-style"><Link to="/webdesign">Bootstrap 5 Website</Link></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Marquee;

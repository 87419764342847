import { useForm } from '@formspree/react';

function Contactform() {
  const [state, handleSubmit, reset] = useForm('{mdoqpnzr}');
 
  const handleFormSubmit = async (event) => {
    await handleSubmit(event);
    if (state.succeeded) {
      reset(); // Reset the form after successful submission
    }
  };

  if (state.succeeded) {
    return <div>Thank you for signing up!</div>;
  }

  return (


    <form onSubmit={handleSubmit} className="contact-form-items">
                                
                                <div className="row g-4">
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                        <div className="form-clt">
                                            <span>Your name*</span>
                                            <input type="text" name="name" id="name" placeholder="Your Name" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                                        <div className="form-clt">
                                            <span>Your Email*</span>
                                            <input type="email" name="email" id="email" placeholder="Your Email" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                                        <div className="form-clt">
                                            <span>Your Phone*</span>
                                            <input type="text" name="phone" id="phone" placeholder="Your Phone" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                                        <div className="form-clt">
                                            <span>Subject*</span>
                                            <input type="text" name="subject" id="subject" placeholder="Subject" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
                                        <div className="form-clt">
                                            <span>Write Message*</span>
                                            <textarea name="message" id="message"
                                                placeholder="Write Message" required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 wow fadeInUp" data-wow-delay=".9s">
                                        <button type="submit" disabled={state.submitting} className="theme-btn">
                                            Send Message <i className="fa-solid fa-arrow-right-long"></i>
                                        </button>
                                    </div>
                                </div>
                            </form> 
                            



  );
};

export default Contactform;
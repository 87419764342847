import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import { Link } from "react-router-dom";

function Services() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Services</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Services
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Service Section Start */}
        <section className="service-section fix section-padding">
            <div className="container">
                <div className="section-title text-center">
                    <span className="wow fadeInUp">OUR SERVICES</span>  
                    <h2 className="wow fadeInUp" data-wow-delay=".3s">
                        Your Vision, Our Expertise, Seamless Solutions
                    </h2>  
                </div>
                <div className="service-wrapper mb-0">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                            <div className="service-box-items box-shadow active">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-1.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/webdesign">Website Design</Link>
                                    </h4>
                                    <p>
                                        Create visually appealing and user-friendly website
                                    </p>
                                    <Link to="/webdesign" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                            <div className="service-box-items box-shadow">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-2.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/webdevelopment">Web Development</Link>
                                    </h4>
                                    <p>
                                        Building websites with custom functionality
                                    </p>
                                    <Link to="/webdevelopment" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                            <div className="service-box-items box-shadow">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-3.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/corporateid">Corporate Identity</Link>
                                    </h4>
                                    <p>
                                        Logo, and Brochures to enhance branding
                                    </p>
                                    <Link to="/corporateid" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".9s">
                            <div className="service-box-items box-shadow">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-4.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/webhosting">Web Hosting</Link>
                                    </h4>
                                    <p>
                                        Reliable and high-performance web hosting
                                    </p>
                                    <Link to="/webhosting" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                            <div className="service-box-items box-shadow">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-10.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/domain">Domain Registration</Link>
                                    </h4>
                                    <p>
                                        We help you choose the perfect domain name
                                    </p>
                                    <Link to="/domain" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                            <div className="service-box-items box-shadow">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-11.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/logodesign">Logo Design</Link>
                                    </h4>
                                    <p>
                                        Logo that effectively communicates your brand
                                    </p>
                                    <Link to="/logodesign" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                            <div className="service-box-items box-shadow">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-12.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/webredesign">Website Re-Design</Link>
                                    </h4>
                                    <p>
                                        Improve the visual appearance of an existing website
                                    </p>
                                    <Link to="/webredesign" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".9s">
                            <div className="service-box-items box-shadow">
                                <div className="icon">
                                   <img src="assets/img/service/icon/s-icon-13.svg" alt="Web Design Services" />
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to="/content">Content Writing</Link>
                                    </h4>
                                    <p>
                                        Writing and editing website content
                                    </p>
                                    <Link to="/content" className="theme-btn-2 mt-3">Read More<i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Services;
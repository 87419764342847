import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Webdesign() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Website Design</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Website Design
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li className="active"><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/webdesign.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Website Design</h3>
                                    <p className="mt-3">
                                    We start by understanding your target audience, their needs, preferences, and pain points. We conduct user research through surveys, interviews, and usability testing to gain insights into user behavior and inform our design decisions.
                                    </p>
                                    <p className="mt-3">
                                    We create wireframes, which are basic skeletal layouts of the website or application, to outline the structure and functionality. This allows us to focus on the user flow, navigation, and content hierarchy without getting distracted by visual design elements.
                                    </p>
                                    <p className="mt-3">
                                    We develop interactive prototypes based on the wireframes to simulate the user experience and test the functionality. Prototyping helps identify usability issues early in the design process and allows for iterative improvements based on user feedback.
                                    </p>
                                    <p className="mt-3">
                                    Once the wireframes and prototypes are approved, we move on to the visual design phase. We create high-fidelity mockups that incorporate branding elements, color schemes, typography, and imagery to bring the design to life and create a cohesive and visually appealing user interface.
                                    </p>
                                    <p className="mt-3">
                                    We ensure that the design is responsive and optimized for viewing on various devices and screen sizes. This involves designing flexible layouts, fluid grids, and scalable images to provide a consistent user experience across desktops, tablets, and smartphones.
                                    </p>
                                    <p className="mt-3">
                                    We prioritize accessibility in our design process to ensure that the website or application is usable by people of all abilities. This includes considerations such as color contrast, text readability, keyboard navigation, and screen reader compatibility.
                                    </p>
                                    <p className="mt-3">
                                    We conduct usability testing with real users to evaluate the effectiveness of the design and identify any usability issues or areas for improvement. Testing may involve tasks, surveys, or eye-tracking studies to gather valuable insights into user behavior and preferences.
                                    </p>
                                    <p className="mt-3">
                                    We believe in an iterative design approach, where we continuously refine and improve the design based on user feedback, testing results, and changing requirements. This allows us to create a design that evolves over time to meet the needs of users and stakeholders.
                                    </p>
                                    <p className="mt-3">
                                    We collaborate closely with our clients throughout the design process, seeking their input and feedback at every stage. We believe in transparent communication and open dialogue to ensure that the final design meets their expectations and business goals.
                                    </p>
                                    
                                    
                                    <div className="image-area">
                                        <div className="row g-4">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="thumb">
                                                    <img src="assets/img/service/details-2.jpg" alt="Web Design Services" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="thumb">
                                                    <img src="assets/img/service/details-3.jpg" alt="Web Design Services" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        By following these principles and practices, we create UX/UI designs that prioritize user needs, enhance usability, and deliver a delightful and memorable user experience. If you're ready to elevate your digital presence with exceptional UX/UI design, please contact us. We're excited to collaborate with you on your next project!
                                    </p>

                                    

                                    
                                </div>
                                <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Webdesign;
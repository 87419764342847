import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";

function Pagenotfound(){
    return(
        <Fragment>

            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>

            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Page Not Found</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Page Not Found
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        {/* Eror Section Start */}
        <section class="Error-section section-padding fix">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="error-items">
                            <div class="error-image">
                                <img src="assets/img/404.png" alt="img" />
                            </div>
                            <h2>
                                Oops! It seems this page got lost <br/> in the conversation
                            </h2>
                            <Link to="/" class="theme-btn wow fadeInUp" data-wow-delay=".8s">
                                Go Back Home
                                <i class="fa-solid fa-arrow-right-long"></i>
                                </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         {/* Footer Section Start */}
         <Footer/>

        </Fragment>
    )
}

export default Pagenotfound;
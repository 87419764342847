import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Canvas(){
    return(
        <Fragment>
            <div className="fix-area">
                <div className="offcanvas__info">
                    <div className="offcanvas__wrapper">
                        <div className="offcanvas__content">
                            <div className="offcanvas__top mb-5 d-flex justify-content-between align-items-center">
                                <div className="offcanvas__logo">
                                    <Link to="/"> <img src="assets/img/logo/logo.png" alt="Website design" /></Link>
                                </div>
                                <div className="offcanvas__close">
                                    <button>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="text d-none d-lg-block">
                                We are a professional service provider specializing in creating and developing websites for businesses, organizations, and individuals.
                            </p>
                            <div className="mobile-menu fix mb-3"></div>
                            <div className="offcanvas__contact">
                                <h4>Contact Info</h4>
                                <ul>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon">
                                            <i className="fal fa-map-marker-alt"></i>
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link to="/contact">230, Pratham Residency,<br />Gurukul Road,<br />Waghodia-Dabhoi Ring Road,<br />Vadodara - 390 025<br />Gujarat-India</Link>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="fal fa-envelope"></i>
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <a href="mailto:info@azent.com"><span
                                                className="mailto:info@idealinfotech.com">info@idealinfotech.com</span></a>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="far fa-phone"></i>
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <a href="tel:+919601888812">+91-96018 88812</a>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="fal fa-clock"></i>
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link to="/contact">Mon-Sat, 09am -05pm</Link>
                                        </div>
                                    </li>

                                </ul>
                                <div className="header-button mt-4">
                                    <Link to="/contact" className="theme-btn text-center"><span>Get A Free Quote<i className="fa-solid fa-arrow-right-long"></i></span></Link>
                                </div>
                                <div className="social-icon d-flex align-items-center">
                                <Link to="https://www.facebook.com/webdsolutions" target="_blank"><i className="fab fa-facebook-f"></i></Link>
                                            <Link to="https://twitter.com/webdsolutions" target="_blank"><i className="fab fa-twitter"></i></Link>
                                            <Link to="https://www.linkedin.com/in/akshay-solanki-a8905b2b7/" target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas__overlay"></div>
        </Fragment>
    )
}

export default Canvas;
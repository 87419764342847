import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Webdevelopment() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Web Development</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Web Development
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li className="active"><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/web-development.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Web Development</h3>
                                    <p className="mt-3">
                                    <strong>Requirement Gathering:</strong> We start by understanding your business goals, target audience, and project requirements. We conduct thorough discussions to gather all necessary information and ensure a clear understanding of your needs.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Planning and Strategy:</strong> We develop a comprehensive project plan and strategy that outlines the project scope, timeline, budget, and deliverables. We define the technical requirements, design considerations, and functionality to be implemented.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Design:</strong> We create visually appealing and user-friendly designs that reflect your brand identity and meet the needs of your target audience. Our design process involves wireframing, prototyping, and iterative design revisions based on your feedback.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Development:</strong> We use cutting-edge technologies and coding languages (such as HTML, CSS, JavaScript, and various frameworks) to build websites and web applications that are robust, scalable, and optimized for performance. We follow best practices in coding, security, and accessibility to ensure high-quality development standards.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Content Management:</strong> If required, we integrate content management systems (CMS) such as WordPress, Drupal, or custom-built solutions to empower you to manage and update your website's content easily. We provide training and support to ensure you're comfortable using the CMS.
                                    </p>
                                    <p className="mt-3">
                                    <strong>E-commerce Integration:</strong> For e-commerce projects, we implement secure and feature-rich e-commerce solutions that enable you to sell products or services online effectively. We integrate payment gateways, shopping carts, product catalogs, and inventory management systems to streamline your online business operations.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Testing and Quality Assurance:</strong> We conduct thorough testing to identify and resolve any bugs, errors, or compatibility issues. We perform cross-browser testing, device testing, and usability testing to ensure a seamless user experience across different platforms and devices.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Deployment:</strong> Once development and testing are complete, we deploy the website or web application to a live server and configure it for optimal performance and security. We handle all aspects of deployment, including domain setup, server configuration, and SSL certificate installation.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Maintenance and Support:</strong> We provide ongoing maintenance and support services to keep your website or web application running smoothly. This includes regular updates, security patches, backups, and technical assistance to address any issues or updates that may arise post-launch.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Performance Optimization:</strong> We continuously monitor and optimize the performance of your website or web application to ensure fast load times, high availability, and optimal user experience. We implement caching strategies, code optimization, and server tuning to improve performance and scalability.
                                    </p>
                                    <p className="mt-3">
                                    By following a comprehensive approach to web development, we create websites and web applications that align with your business objectives, engage your audience, and drive results. If you're ready to embark on a web development project or have any questions, please contact us. We're here to help you bring your digital vision to life.
                                    </p>
                                    
                                    
                                    
                                </div>
                                <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Webdevelopment;
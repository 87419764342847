
import './App.css';
import About from './pages/About';
import Contact from './pages/Contact';
import Index from './pages/Index';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import Webdesign from './pages/Webdesign';
import Webdevelopment from './pages/Webdevelopment';
import Corporateid from './pages/Corporateid';
import Webhosting from './pages/Webhosting';
import Domain from './pages/Domain';
import Logodesign from './pages/Logodesign';
import Webredesign from './pages/Webredesign';
import Content from './pages/Content';
import Faq from './pages/Faq';
import ScrollToTop from './pages/ScrollToTop';
import Pagenotfound from './pages/Pagenotfound';




function App() {
  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <Routes>
        
        <Route path='*' element={<Pagenotfound/>} />
        <Route path='/' element={<Index/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/portfolio' element={<Portfolio/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/webdesign' element={<Webdesign/>} />
        <Route path='/webdevelopment' element={<Webdevelopment/>} />
        <Route path='/corporateid' element={<Corporateid/>} />
        <Route path='/webhosting' element={<Webhosting/>} />
        <Route path='/domain' element={<Domain/>} />
        <Route path='/logodesign' element={<Logodesign/>} />
        <Route path='/webredesign' element={<Webredesign/>} />
        <Route path='/content' element={<Content/>} />
        <Route path='/faq' element={<Faq/>} />
        
        
        
        
        
        
        </Routes>
      </Router>
      
      
    </div>


    

  );
}

export default App;

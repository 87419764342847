import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Footer(){
    return(
        <Fragment>
            <footer className="footer-section">
                <div className="footer-widgets-wrapper footer-bg">
                    <div className="shape-1">
                        <img src="assets/img/footer-shape-1.png" alt="Web Design Services" />
                    </div>
                    <div className="shape-2">
                        <img src="assets/img/footer-shape-2.png" alt="Web Design Services" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                                <div className="single-footer-widget">
                                    <div className="widget-head">
                                        <Link to="/"><img src="assets/img/logo/logo-white.png" alt="Website design" /></Link>
                                    </div>
                                    <div className="footer-content">
                                        <p>
                                            We are a professional service provider specializing in creating and developing websites for businesses, organizations, and individuals.
                                        </p>
                                        <div className="social-icon d-flex align-items-center">
                                            <Link to="https://www.facebook.com/webdsolutions" target="_blank"><i className="fab fa-facebook-f"></i></Link>
                                            <Link to="https://twitter.com/webdsolutions" target="_blank"><i className="fab fa-twitter"></i></Link>
                                            <Link to="https://www.instagram.com/idealinfotech_/" target="_blank"><i className="fab fa-instagram"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 ps-lg-5 wow fadeInUp" data-wow-delay=".5s">
                                <div className="single-footer-widget">
                                    <div className="widget-head">
                                        <h3>Quick Links</h3>
                                    </div>
                                    <ul className="list-area">
                                        <li>
                                            <Link to="/"> <i className="fa-solid fa-chevron-right"></i>Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/about"><i className="fa-solid fa-chevron-right"></i>About</Link>
                                        </li>
                                        <li>
                                            <Link to="/services"><i className="fa-solid fa-chevron-right"></i>Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/portfolio"><i className="fa-solid fa-chevron-right"></i>Portfolio</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact"><i className="fa-solid fa-chevron-right"></i>Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 ps-lg-5 wow fadeInUp" data-wow-delay=".5s">
                                <div className="single-footer-widget style-margin">
                                    <div className="widget-head">
                                        <h3>Our Services</h3>
                                    </div>
                                    <ul className="list-area">
                                        <li>
                                            <Link to="/webdesign"><i className="fa-solid fa-chevron-right"></i>Website Design</Link>
                                        </li>
                                        <li>
                                            <Link to="/webdevelopment"><i className="fa-solid fa-chevron-right"></i>Web Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/corporateid"><i className="fa-solid fa-chevron-right"></i>Corporate Identity</Link>
                                        </li>
                                        <li>
                                            <Link to="/webhosting"><i className="fa-solid fa-chevron-right"></i>Web Hosting</Link>
                                        </li>
                                        <li>
                                            <Link to="/domain"><i className="fa-solid fa-chevron-right"></i>Domain Registration</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 ps-xl-5 wow fadeInUp" data-wow-delay=".9s">
                                <div className="single-footer-widget">
                                    <div className="widget-head">
                                        <h3>Contact Us</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul className="contact-info">
                                            <li>
                                                <i className="fas fa-map-marker-alt"></i>
                                                Vadodara-390 025, India
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-phone-volume"></i>
                                                <a href="tel:+919601888812">+91-96018 88812</a>
                                            </li>
                                            <li>
                                                <i className="fa-regular fa-envelope"></i>
                                                <a href="mailto:info@idealinfotech.com">info@idealinfotech.com</a>
                                            </li>
                                        </ul>
                                        <Link to="/contact" className="theme-btn hover-white mt-4">Get A Quote <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-wrapper d-flex align-items-center justify-content-between">
                            <p className="wow fadeInLeft color-2" data-wow-delay=".3s">
                                Copyright © 2024, <a href="index.html">Idealinfotech</a>. All rights reserved.
                            </p>
                            <ul className="footer-menu wow fadeInRight" data-wow-delay=".5s">
                                <li>
                                    <Link to="/about">Company</Link>
                                </li>
                                <li>
                                <Link to="/contact">Support</Link>
                                </li>
                                <li>
                                <Link to="/faq">FAQ</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <a href="javascript:void(0)" id="scrollUp" className="scroll-icon">
                        <i className="far fa-arrow-up"></i>
                    </a> */}
                </div>
            </footer>
        </Fragment>
    )
}

export default Footer;
import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Webredesign() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Website Re-Design</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Website Re-Design
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li className="active"><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/website-re-design.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Website Re-Design</h3>
                                    <p className="mt-3">
                                    <strong>Evaluation:</strong> We start by evaluating your current website to identify its strengths, weaknesses, and areas for improvement. We analyze factors such as design aesthetics, user interface, navigation structure, content quality, and performance metrics.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Goal Setting:</strong> We work closely with you to define clear goals and objectives for the website redesign project. Whether it's improving user engagement, increasing conversion rates, enhancing brand consistency, or optimizing for search engines, we ensure that the redesign aligns with your business objectives.
                                    </p>
                                    <p className="mt-3">
                                    <strong>User Research:</strong> Understanding your target audience is essential for creating a website that resonates with them. We conduct user research, including surveys, interviews, and usability testing, to gain insights into user preferences, behaviors, and pain points.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Concept Development:</strong> Based on our findings, we develop design concepts for the website redesign. These concepts explore different visual styles, typography, colors, and symbolism.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Feedback and Revision:</strong> We present the initial design concepts to you for feedback. Your input is crucial in refining the design to meet your preferences and expectations. We iterate on the design based on your feedback until we achieve the perfect balance of aesthetics and functionality.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Content Strategy:</strong> Content is a critical component of any website redesign. We assess your existing content, identify gaps or outdated information, and develop a content strategy to ensure that the new website effectively communicates your message and meets the needs of your audience.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Development:</strong> Once the design and content strategy are approved, we proceed with the development phase. Our team of developers implements the design using the latest web technologies and coding standards to ensure a responsive, accessible, and high-performance website.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Testing and Feedback:</strong> Before launch, we conduct thorough testing to ensure that the redesigned website functions flawlessly across different devices and browsers. We also gather feedback from stakeholders and beta users to make any necessary refinements or adjustments.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Launch and Optimization:</strong> After final approval, we launch the redesigned website and monitor its performance closely. We track key performance indicators (KPIs) such as traffic, engagement, and conversions to measure the success of the redesign. We also continue to optimize the website based on user feedback and analytics data.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Training and Support:</strong> We provide training and support to your team to ensure they are comfortable managing and updating the redesigned website. We also offer ongoing support and maintenance services to address any issues or updates that may arise post-launch.
                                    </p>
                                    <p className="mt-3">
                                    By following this comprehensive approach to website redesign, we create websites that not only look great but also deliver exceptional user experiences and drive results for your business. If you're considering a website redesign or have any questions, please contact us. We're here to help you elevate your online presence and achieve your business goals.
                                    </p>
                                   
                                    
                                </div>
                                <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Webredesign;
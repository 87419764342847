import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Content() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Content Writing</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Content Writing
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li className="active"><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/content-writing.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Content Writing</h3>
                                    <p className="mt-3">
                                    <strong>Understanding Your Audience:</strong> We start by understanding your target audience, their needs, preferences, and pain points. We conduct research to gain insights into their demographics, behavior, and interests to tailor the content accordingly.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Defining Content Goals:</strong> We work closely with you to define clear content goals and objectives. Whether it's to educate, inform, entertain, or persuade your audience, we ensure that the content aligns with your overall business objectives and messaging.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Keyword Research:</strong> For SEO (Search Engine Optimization) purposes, we conduct keyword research to identify relevant keywords and phrases that your audience is searching for. We integrate these keywords strategically into the content to improve search engine visibility and attract organic traffic.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Content Strategy:</strong> Based on our understanding of your audience and goals, we develop a content strategy that outlines the topics, themes, and formats for the content. We create an editorial calendar to plan and organize the content creation process effectively.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Creating Engaging Content:</strong> We write high-quality, engaging, and informative content that resonates with your audience and captures their attention. Whether it's blog posts, articles, website copy, social media posts, or marketing materials, we craft compelling content that tells your brand story and drives engagement.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Optimizing for Readability:</strong> We optimize the content for readability by using clear and concise language, breaking up text with subheadings and bullet points, and formatting it for easy scanning. We ensure that the content is well-structured, organized, and easy to digest.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Maintaining Brand Voice:</strong> We maintain consistency in your brand voice and tone across all content channels to reinforce your brand identity and build brand recognition. We adapt the tone and style of the content to suit the platform and audience while staying true to your brand's personality.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Proofreading and Editing:</strong> We meticulously proofread and edit the content to ensure accuracy, clarity, and coherence. We check for spelling and grammar errors, factual inaccuracies, and consistency in style and tone to deliver polished and professional content.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Feedback and Revisions:</strong> We value your input and feedback throughout the content writing process. We provide opportunities for review and revisions to ensure that the content meets your expectations and aligns with your vision.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Performance Monitoring:</strong> We monitor the performance of the content using analytics tools to track key metrics such as engagement, traffic, and conversions. We analyze the data to gain insights into what resonates with your audience and optimize future content accordingly.
                                    </p>
                                    <p className="mt-3">
                                    By following these principles and practices, we create content that captivates your audience, builds brand credibility, and drives results. If you're ready to elevate your content strategy or have any questions, please contact us. We're here to help you create compelling content that resonates with your audience and achieves your business goals.
                                    </p>
                                   
                                    
                                </div>
                                <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Content;
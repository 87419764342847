import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Menu(){
    return(
        <Fragment>
            <header>
                <div className="header-top-section fix">
                    <div className="container-fluid">
                        <div className="header-top-wrapper">
                            <ul className="contact-list">
                                <li>
                                    <i className="far fa-envelope"></i>
                                    <a href="mailto:info@idealinfotech.com" className="link">info@idealinfotech.com</a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-phone-volume"></i>
                                    <a href="tel:+919601888812">+91-96018 88812</a>
                                </li>
                            </ul>
                            <div className="top-right">
                                <div className="social-icon d-flex align-items-center">
                                    <span>Follow Us:</span>
                                            <Link to="https://www.facebook.com/webdsolutions" target="_blank"><i className="fab fa-facebook-f"></i></Link>
                                            <Link to="https://twitter.com/webdsolutions" target="_blank"><i className="fab fa-twitter"></i></Link>
                                            <Link to="https://www.instagram.com/idealinfotech_/" target="_blank"><i className="fab fa-instagram"></i></Link>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="header-sticky" className="header-1">
                    <div className="container-fluid">
                        <div className="mega-menu-wrapper">
                            <div className="header-main style-2">
                                <div className="header-left">
                                    <div className="logo">
                                        
                                        <Link to="/" className="header-logo"><img src="assets/img/logo/logo.png" alt="Web Design Services" /></Link>  
                                        
                                    </div>
                                </div>
                                <div className="header-right d-flex justify-content-end align-items-center">
                                    <div className="mean__menu-wrapper">
                                        <div className="main-menu">
                                            <nav id="mobile-menu">
                                                <ul>
                                                    <li>
                                                        <Link to="/">Home</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/about">About</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services">Services <i className="fas fa-angle-down"></i></Link>
                                                        
                                                        <ul className="submenu">
                                                            <li><Link to="/webdesign">Website Design</Link></li>
                                                            <li><Link to="/webdevelopment">Web Development</Link></li>
                                                            <li><Link to="/corporateid">Corporate Identity</Link></li>
                                                            <li><Link to="/webhosting">Web Hosting</Link></li>
                                                            <li><Link to="/domain">Domain Registration</Link></li>
                                                            <li><Link to="/logodesign">Logo Design</Link></li>
                                                            <li><Link to="/webredesign">Website Re-Design</Link></li>
                                                            <li><Link to="/content">Content Writing</Link></li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <Link to="/portfolio">Portfolio</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact">Contact</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    <div className="header-button">
                                        <Link to="/contact" className="theme-btn"><span>Get A Quote <i className="fa-solid fa-arrow-right-long"></i></span></Link>
                                    </div>
                                    <div className="header__hamburger d-xl-block my-auto">
                                        <div className="sidebar__toggle">
                                            <i className="fas fa-bars"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default Menu;
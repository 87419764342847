import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Logodesign() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Logo Design</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Logo Design
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li className="active"><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/logo-design.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Logo Design</h3>
                                    <p className="mt-3">
                                    <strong>Discovery:</strong> We start by getting to know your business, its values, mission, target audience, and industry landscape. We conduct research to understand your competitors and identify opportunities for differentiation.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Creative Brief:</strong> We work closely with you to define the objectives and requirements for the logo design project. We discuss aspects such as design preferences, color schemes, typography, symbolism, and any specific elements you want to incorporate into the logo.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Concept Development:</strong> Based on the creative brief and our research, we brainstorm ideas and develop multiple design concepts for the logo. These concepts explore different visual styles, layouts, and iconography to capture the essence of your brand and resonate with your target audience.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Feedback and Revision:</strong> We present the initial logo concepts to you for feedback. Your input is crucial in refining the designs to meet your preferences and expectations. We iterate on the designs based on your feedback until we achieve a logo that effectively represents your brand identity and aligns with your vision.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Color and Typography:</strong> We carefully select colors and typography that reflect your brand personality and evoke the desired emotions and associations. We consider factors such as readability, scalability, and versatility to ensure that the logo works well in various contexts and applications.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Finalization:</strong> Once a logo concept is approved, we finalize the design and prepare the necessary files for delivery. We provide you with high-resolution files in various formats (such as vector files, PNG, JPEG) suitable for print and digital use. We also create a brand style guide that outlines logo usage guidelines to maintain consistency across all communications.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Presentation:</strong> We present the final logo design along with the brand style guide, explaining the rationale behind the design choices and how to use the logo effectively. We ensure that you're equipped with all the necessary resources and information to implement the logo successfully across your branding materials.
                                    </p>
                                    <p className="mt-3">
                                    <strong>Support:</strong> Even after the logo is delivered, we're here to provide ongoing support and assistance. Whether you need additional file formats, advice on logo usage, or updates in the future, we're just a message away.
                                    </p>
                                    <p className="mt-3">
                                    By following this comprehensive approach to logo design, we create logos that are not only visually appealing but also meaningful, memorable, and reflective of your brand identity. If you're ready to elevate your brand with a new logo or have any questions, please contact us. We're excited to collaborate with you on your logo design project!
                                    </p>
                                   
                                    
                                </div>
                                    <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Logodesign;
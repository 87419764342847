import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function About() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.webp")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="Web Design Services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="Web Design Services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">About Us</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                About Us
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* About Section Start */}
            <section className="about-section section-padding fix bg-cover"
                style={{ backgroundImage: `url("assets/img/service/service-bg-2.jpg")` }}>
                <div className="container">
                    <div className="about-wrapper style-2">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-image-items">
                                    <div className="circle-shape">
                                        <img src="assets/img/about/circle.png" alt="Web Design Services" />
                                    </div>
                                    <div className="counter-shape float-bob-y">
                                        <div className="icon">
                                            <img src="assets/img/about/icon-1.svg" alt="Web Design Services" />
                                        </div>
                                        <div className="content">
                                            <h3><span className="count">17</span>Years</h3>
                                            <p>Of Experience</p>
                                        </div>
                                    </div>
                                    <div className="about-image-1 bg-cover wow fadeInLeft" data-wow-delay=".3s"
                                        style={{ backgroundImage: `url("assets/img/about/03.png")` }}>
                                        <div className="about-image-2 wow fadeInUp" data-wow-delay=".5s">
                                            <img src="assets/img/about/04.jpg" alt="Web Design Services" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4 mt-lg-0">
                                <div className="about-content">
                                    <div className="section-title">
                                        <span className="wow fadeInUp">Know More About Us</span>
                                        <h2 className="wow fadeInUp" data-wow-delay=".3s">
                                            Commitment to delivering an exceptional <span>Online Experience</span>
                                        </h2>
                                    </div>
                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        At Ideal infotech, we're passionate about crafting exceptional digital experiences that
                                        empower businesses to thrive in the online world. With a team of creative minds and
                                        technical experts, we specialize in delivering cutting-edge web solutions that captivate
                                        audiences, drive engagement, and achieve measurable results.<br /><br />
                                    </p>

                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        Ideal infotech is a premier web design and development company located in Vadodara,
                                        India, dedicated to providing top-notch web solutions to clients worldwide at affordable
                                        prices. With a focus on quality and utilizing the latest technologies, we strive to
                                        deliver exceptional results tailored to meet each client's unique needs.<br /><br />
                                    </p>


                                </div>
                            </div>



                            {/* Side */}
                            <div className="col-lg-12 mt-4 mt-lg-0">
                                <div className="about-content">

                                    <br /><br /><br />

                                    <h3 className="wow fadeInDown" data-wow-delay=".5s">What Sets Us Apart:<br /><br /></h3>

                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        <strong>Innovative Approach</strong>: We believe in pushing boundaries and embracing
                                        innovation to stay ahead of the curve. From sleek designs to seamless functionality,
                                        we're committed to delivering solutions that exceed expectations and leave a lasting
                                        impression.<br /><br />
                                    </p>

                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        <strong>Client-Centric Focus</strong>: Our clients are at the heart of everything we do.
                                        We take the time to understand their unique needs, goals, and challenges, and tailor our
                                        solutions to meet their specific requirements. Your success is our success, and we're
                                        dedicated to helping you achieve your business objectives.<br /><br />
                                    </p>

                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        <strong>Expertise and Experience</strong>: With years of industry experience and a deep
                                        understanding of the latest technologies and trends, our team brings a wealth of
                                        knowledge and expertise to every project. From small startups to large enterprises, we
                                        have the skills and capabilities to tackle projects of any size and complexity.<br /><br />
                                    </p>

                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        <strong>Collaborative Approach</strong>: We believe that great results are achieved
                                        through collaboration and teamwork. We work closely with our clients every step of the
                                        way, providing transparent communication, regular updates, and ongoing support to ensure
                                        their vision is brought to life seamlessly.<br /><br />
                                    </p>



                                    <h3 className="wow fadeInDown" data-wow-delay=".5s">Our Services:<br /><br /></h3>


                                    <ul className="list">
                                        <li>
                                            <i className="far fa-check me-2"></i>Responsive Website Design
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Corporate Website Development
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Ecommerce Solutions
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Content Management Systems
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Payment Gateway Services
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Professional Logo Design
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Graphic Design
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>PSD to HTML5 Conversion
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Website Re-design
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Domain Name Registration
                                        </li>
                                        <li>
                                            <i className="far fa-check me-2"></i>Web Hosting<br /><br />
                                        </li>
                                    </ul>



                                    <h3 className="wow fadeInDown" data-wow-delay=".5s">Let's Work Together:<br /><br /></h3>

                                    <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
                                        Whether you're looking to launch a new website, revamp your online presence, or drive
                                        more traffic to your business, we're here to help. Get in touch with us today to discuss
                                        your project and discover how we can turn your vision into reality.
                                    </p>



                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us<i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* Side */}
                        </div>
                    </div>
                </div>
            </section>

            {/* Offer Section Start */}
            <section className="offer-section fix section-bg-2 section-padding">
                <div className="line-shape">
                    <img src="assets/img/team/line-shape.png" alt="Web Design Services" />
                </div>
                <div className="mask-shape">
                    <img src="assets/img/team/mask-shape.png" alt="Web Design Services" />
                </div>
                <div className="container">
                    <div className="section-title text-center">
                        <span className="wow fadeInUp">Ideal Infotech</span>
                        <h2 className="text-white wow fadeInUp" data-wow-delay=".3s">
                            Turning Goals <br /> Into Achievements
                        </h2>
                    </div>


                    <div className="container">
                        <div className="achievement-wrapper style-2">

                            <div className="counter-area">
                                <div className="counter-items wow fadeInUp" data-wow-delay=".3s">
                                    <div className="icon">
                                        <img src="assets/img/achievement-icon/01.svg" alt="Web Design Services" />
                                    </div>
                                    <div className="content">
                                        <h2><span className="count">500</span>+</h2>
                                        <p>Satisfied Clients</p>
                                    </div>
                                </div>
                                <div className="counter-items wow fadeInUp" data-wow-delay=".5s">
                                    <div className="icon">
                                        <img src="assets/img/achievement-icon/02.svg" alt="Web Design Services" />
                                    </div>
                                    <div className="content">
                                        <h2><span className="count">650</span>+</h2>
                                        <p>Finished Projects</p>
                                    </div>
                                </div>
                                <div className="counter-items wow fadeInUp" data-wow-delay=".7s">
                                    <div className="icon">
                                        <img src="assets/img/achievement-icon/03.svg" alt="Web Design Services" />
                                    </div>
                                    <div className="content">
                                        <h2><span className="count">10</span>+</h2>
                                        <p>Skilled Experts</p>
                                    </div>
                                </div>
                                <div className="counter-items wow fadeInUp" data-wow-delay=".9s">
                                    <div className="icon">
                                        <img src="assets/img/achievement-icon/04.svg" alt="Web Design Services" />
                                    </div>
                                    <div className="content">
                                        <h2><span className="count">800</span>+</h2>
                                        <p>Logo Created</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* Project Section Start */}
            

            {/* Marque Section Start */}
            <Marquee/>
            
            {/* Brand Section Start */}
            

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default About;
import React, { Fragment } from "react";
import Footer from "./Footer";
import Canvas from "./Canvas";
import Menuu from "./Menuu";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";

function Webhosting() {
    return (
        <Fragment>
            {/* Offcanvas Area Start */}
            <Canvas/>

            {/* Header Section Start */}
            <Menuu/>


            {/* Breadcrumb Section Start */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: `url("assets/img/breadcrumb.jpg")` }}>
                <div className="border-shape">
                    <img src="assets/img/element.png" alt="We design services" />
                </div>
                <div className="line-shape">
                    <img src="assets/img/line-element.png" alt="We design services" />
                </div>
                <div className="container">
                    <div className="page-heading">
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">Web Hosting</h1>
                        <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="fas fa-chevron-right"></i>
                            </li>
                            <li>
                                Web Hosting
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        {/* Service Details Section Start */}
        <section className="service-details-section fix section-padding">
            <div className="container">
                <div className="service-details-wrapper">
                    <div className="row g-4">
                        {/* sideabr */}
                        <div className="col-12 col-lg-4 order-2 order-md-1">
                            <div className="main-sidebar">
                                <div className="single-sidebar-widget">
                                    <div className="wid-title">
                                        <h3>Our Services</h3>
                                    </div>
                                    <div className="widget-categories">
                                        <ul>
                                            <li><Link to="/webdesign">Website Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webdevelopment">Web Development</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/corporateid">Corporate Identity</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li className="active"><Link to="/webhosting">Web Hosting</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/domain">Domain Registration</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/logodesign">Logo Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/webredesign">Website Re-Design</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                            <li><Link to="/content">Content Writing</Link><i className="fa-solid fa-arrow-right-long"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="single-sidebar-image bg-cover" style={{ backgroundImage: `url("assets/img/service/post.jpg")` }}>
                                    <div className="contact-text">
                                        <div className="icon">
                                            <i className="fa-solid fa-phone"></i>
                                       </div>
                                       <h4>Need Help? Call Us Now</h4>
                                       <h5>
                                        <a href="tel:+919601888812">+91-96018 88812</a>
                                       </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 order-1 order-md-2">
                            <div className="service-details-items">
                                <div className="details-image">
                                    <img src="assets/img/service/web-hosting.jpg" alt="Web Design Services" />
                                </div>
                                <div className="details-content">
                                    <h3>Web Hosting</h3>
                                    <p className="mt-3">
                                        <strong>Hosting Solutions</strong>: We offer a range of hosting solutions tailored to meet your specific needs and budget. Whether you're launching a personal blog, a small business website, or a large e-commerce platform, we have hosting plans that fit your requirements.
                                    </p>
                                    <p className="mt-3">
                                        <strong>Reliable Infrastructure:</strong> Our hosting services are built on reliable and high-performance infrastructure, ensuring that your website remains accessible and responsive at all times. We utilize state-of-the-art data centers, redundant network connections, and advanced security measures to safeguard your website's data and uptime.
                                    </p>
                                    <p className="mt-3">
                                        <strong>Scalability:</strong> Our hosting plans are designed to scale with your business as it grows. Whether you experience a sudden surge in traffic or need additional resources to accommodate new features or functionality, we offer flexible scaling options to meet your evolving needs.
                                    </p>
                                    <p className="mt-3">
                                        <strong>Performance Optimization:</strong> We optimize our hosting environment for speed, reliability, and performance to ensure that your website loads quickly and delivers a seamless user experience. We leverage caching techniques, content delivery networks (CDNs), and other optimization strategies to minimize page load times and improve site performance.
                                    </p>
                                    <p className="mt-3">
                                        <strong>Security Measures:</strong> We take security seriously and implement robust security measures to protect your website from threats such as malware, hacking attempts, and data breaches. Our hosting environment includes firewalls, intrusion detection systems, regular security audits, and automatic backups to keep your data safe and secure.
                                    </p>
                                    <p className="mt-3">
                                        <strong>24/7 Support:</strong> Our experienced support team is available 24/7 to assist you with any technical issues or questions you may have. Whether you need help setting up your hosting account, troubleshooting an issue, or optimizing your website's performance, we're here to provide prompt and reliable support.
                                    </p>
                                    <p className="mt-3">
                                        <strong>Control Panel Access:</strong> We provide you with access to a user-friendly control panel (such as cPanel or Plesk) that allows you to manage various aspects of your hosting account, including file management, email accounts, databases, and domain settings. You have full control and flexibility to customize your hosting environment according to your preferences.
                                    </p>
                                    <p className="mt-3">
                                        <strong>Regular Updates and Maintenance:</strong> We regularly update our server software and perform maintenance tasks to ensure optimal performance, stability, and security. This includes installing security patches, software updates, and performance optimizations to keep your website running smoothly.
                                    </p>
                                    <p className="mt-3">
                                        By choosing us as your web hosting provider, you can rest assured that your website is in good hands. We offer reliable hosting solutions, exceptional support, and peace of mind, allowing you to focus on growing your business without worrying about technical infrastructure. If you're ready to get started with web hosting or have any questions, please contact us. We're here to help you succeed online.
                                    </p>
                                    
                                </div>
                                <br/>
                                    <div className="about-author">
                                        <div className="about-button wow fadeInUp" data-wow-delay=".5s">
                                            <Link to="/contact" className="theme-btn">Contact Us <i className="fa-solid fa-arrow-right-long"></i></Link>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
            

            {/* Marquee Section Start */}
            <Marquee/>

            {/* Footer Section Start */}
            <Footer/>
        </Fragment>
    )
}

export default Webhosting;